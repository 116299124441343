body {
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;

}

p{
  font-size: 1.8rem;
}
h1{
  font-size: 7rem;
}

.App{
  width: 100%;
  height: 100%;
  /* or 100vh */
  position: relative;
  background-color: rgba(0,0,0,0.4);
  color:#fff;
}

.App:before{
  content:'';
  background: url('./assets/weather.jpg') no-repeat center center/cover ;
  position: absolute;
  width:100%;
  height:100%;
  top:0;
  left: 0;
  z-index: -1;

}

.app__container{
  max-width:700px;
  height:700px;
  margin:auto;
  padding: 0 1rem;
  position: relative;
  top:10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}
.app__top{
  width: 100%;
  margin: 1rem auto;

}

.app__description{
  position: relative;
  right: -90%;
  transform-origin: 0 0;
  transform: rotate(269deg);
}

.app__bottom{
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  width: 100%;
  /* margin: 1rem auto; */
  margin-bottom: 8rem;
  padding: 1rem;
  border-radius: 12px;
  background-color: rgba(255,255,255,0.2)
}

.bold{
  font-weight: 700;
}

.app__search{
  text-align: center;
  padding: 1rem;

}
.app__input{
  padding: .7rem 1.5rem;
  font-size: medium;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  background: rgba(255,255,255,0.1)
  
}
::placeholder{
  color:#f8f8f8;
}
